import * as React from 'react'
import { collection, doc, getDoc } from 'firebase/firestore'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'

import {
  Card,
  Button,
  Typography as Text,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import {
  TabPanel,
  a11yProps,
} from '../../DashboardComponents/TabPanel/TabPanel'
import SearchBar from '../../DashboardComponents/SearchBar/SearchBar'
import Widget from '../Widget'
import Footer from '../../DashboardComponents/Footer/Footer'
import {
  updateTabValue,
  updateTabValue2,
  updateQuery,
  fetchStudentServiceCenterCases,
} from './studentServiceCenterSlice'
import studentServiceCenterStyles from './studentServiceCenterStyles'
import Loading from '../helpers/Loading'
import {
  RutgersTab,
  RutgersTabs,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import CaseIndicator from '../../DashboardComponents/CaseIndicator/CaseIndicator'
import { firestore } from '../../../../../utils/firebase'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'

// eslint-disable-next-line no-unused-vars
function StudentServiceCenter({ className, title, widgetId }) {
  const dispatch = useDispatch()
  const classes = studentServiceCenterStyles()
  const [locationCodeVal, updateLocationCode] = React.useState(1)
  const { events, caseStatus } = useSelector((s) => s.studentServiceCenter)
  React.useEffect(() => {
    dispatch(fetchStudentServiceCenterCases())
    const userDocRef = doc(
      collection(firestore, 'users'),
      localStorage.getItem('user') ?? 'visitor',
    )

    const userInfoDocRef = doc(collection(userDocRef, 'userInfo'), 'Info')
    const getDocAsync = async () => {
      const document = await getDoc(userInfoDocRef)
      if (!document?.data()) {
        return
      }
      const { locationCode } = document.data()
      updateLocationCode(locationCode)
    }
    getDocAsync()
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content className={classes.content}>
        {renderSSCState(widgetId, title, events, caseStatus, locationCodeVal)}
      </Widget.Content>
    </Widget>
  )
}

function renderSSCState(widgetId, title, events, caseStatus, locationCodeVal) {
  // TODO: Handle Proper Loading and Error States
  switch (caseStatus) {
    case 'fulfilled':
      return (
        <Loaded
          widgetId={widgetId}
          events={events}
          locationCodeVal={locationCodeVal}
          title={title}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const { locationCodeVal, widgetId, title } = props
  const dispatch = useDispatch()
  const { value, value2, query, myOpenCases, myClosedCases } = useSelector(
    (s) => s.studentServiceCenter,
  )
  const classes = studentServiceCenterStyles()

  const handleChange = (event, newValue) => {
    dispatch(updateTabValue(newValue))
  }

  const handleChange2 = (event, newValue) => {
    dispatch(updateTabValue2(newValue))
  }

  const searchOnRequest = () => {
    // Spaces are replaced by '%20' in the search link
    const redirectString = query.toString().split(' ').join('%20')
    // https://uat-rutgers.cs3.force.com/OneStopPortal/s/global-search/(term)
    const link = `${process.env.REACT_APP_STUDENT_SERVICE_CENTER_HYPERLINK}/s/global-search/${redirectString}`

    // Opens new tab with search bar value
    window.open(link, '_blank')
  }

  const handleNewCaseLink = async () => {
    // get location from person identifier endpoint?
    let location
    switch (locationCodeVal) {
      case 1:
        location = `New-Brunswick`
        break
      case 2:
        location = `Newark`
        break
      case 3:
        location = `Camden`
        break
      default:
        location = `New-Brunswick`
    }
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        title,
        `${process.env.REACT_APP_STUDENT_SERVICE_CENTER_SSO}?providerId=${process.env.REACT_APP_STUDENT_SERVICE_CENTER_HYPERLINK_TWO}&target=${process.env.REACT_APP_STUDENT_SERVICE_CENTER_HYPERLINK_TWO}/s/${location}`,
      )
    }
    window.open(
      `${process.env.REACT_APP_STUDENT_SERVICE_CENTER_SSO}?providerId=${process.env.REACT_APP_STUDENT_SERVICE_CENTER_HYPERLINK_TWO}&target=${process.env.REACT_APP_STUDENT_SERVICE_CENTER_HYPERLINK_TWO}/s/${location}`,
    )
  }
  const handleOneStopURL = async () => {
    // NB: `https://emnb.rutgers.edu/one-stop-overview/`
    // NW: `https://myrun.newark.rutgers.edu/`
    // CAM: `https://www.camden.rutgers.edu/osssc`
    let link
    switch (locationCodeVal) {
      case 1:
        link = `https://emnb.rutgers.edu/one-stop-overview/`
        break
      case 2:
        link = `https://myrun.newark.rutgers.edu/`
        break
      case 3:
        link = `https://www.camden.rutgers.edu/osssc`
        break
      default:
        link = `https://emnb.rutgers.edu/one-stop-overview/`
    }

    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardLinkEvent', widgetId, title, link)
    }
    window.open(link)
  }

  return (
    <Grid container direction="column" className={classes.gridContainer}>
      <Grid item className={classes.searchBar}>
        <Text variant="h4" align="center" className={classes.oneStopHeader}>
          <b>ONE</b>
          <Text
            variant="h4"
            component="span"
            className={classes.oneStopHeaderSpan}
          >
            <b>STOP</b>
          </Text>
        </Text>
        <SearchBar
          placeholder="How can we help you?"
          value={query}
          onChange={(result) => dispatch(updateQuery(result))}
          onCancelSearch={() =>
            dispatch(updateQuery({ target: { value: '' } }))
          }
          webViewMode
          onRequestSearch={searchOnRequest}
        />
      </Grid>
      <Grid item className={classes.modalTabs}>
        <RutgersTabs value={value} onChange={handleChange} aria-label="Top Tab">
          <RutgersTab label="New Case" {...a11yProps(0)} />
          <RutgersTab
            label={
              <Box display="flex">
                <Text className={classes.tabTextLabel}>My Cases</Text>
                {myOpenCases && myOpenCases?.length > 0 && (
                  <CaseIndicator value={myOpenCases?.length} />
                )}
              </Box>
            }
            {...a11yProps(1)}
          />
        </RutgersTabs>
      </Grid>
      <Grid item container className={classes.tabPanel}>
        <TabPanel value={value} index={0} className={classes.smallTabPanel}>
          {locationCodeVal === '4' || locationCodeVal === '5' ? (
            <RBHSStudentNewCaseTab
              widgetId={widgetId}
              dispatch={dispatch}
              classes={classes}
            />
          ) : (
            <>
              <Grid item className={classes.eventGridItem}>
                <Text variant="h5" align="center">
                  Welcome to the{' '}
                  <Text variant="h5" component="span">
                    ONE STOP
                  </Text>
                  !
                </Text>
              </Grid>
              <Grid item className={classes.descriptionGridItem}>
                <Text variant="body1" align="center">
                  An integrated cross-functional service for{' '}
                  <b>financial aid, student accounts, and registration.</b>
                </Text>
              </Grid>
              <Grid item className={classes.caseButtonGridItem}>
                <Button
                  variant="contained"
                  className={classes.newCaseButton}
                  onClick={handleNewCaseLink}
                >
                  <Text variant="h6">Submit Your Inquiry</Text>
                </Button>
              </Grid>
            </>
          )}
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.smallTabPanel}>
          <RutgersTabs
            value={value2}
            onChange={handleChange2}
            aria-label="Top Tab"
          >
            <RutgersTab label="Open Cases" {...a11yProps(0)} />
            <RutgersTab label="Closed Cases" {...a11yProps(1)} />
          </RutgersTabs>
          <TabPanel value={value2} index={0} clasName={classes.tabPanelCases}>
            {myOpenCases.length > 0 ? (
              myOpenCases.map((caseObject) => (
                <Case
                  key={caseObject.Id}
                  widgetId={widgetId}
                  title={title}
                  dispatch={dispatch}
                  caseObject={caseObject}
                  eventCardStyle={classes.casesNewCard}
                  eventCardTextColor={classes.casesNewCasesTextColor}
                />
              ))
            ) : (
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <CheckCircleOutlineIcon className={classes.circleIcon} />
                </Grid>

                <Grid item>
                  <Text variant="subtitle1">No Cases to Display</Text>
                </Grid>
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={value2} index={1} clasName={classes.tabPanelCases}>
            {myClosedCases.length > 0 ? (
              myClosedCases.map((caseObject) => (
                <Case
                  key={caseObject.Id}
                  widgetId={widgetId}
                  title={title}
                  dispatch={dispatch}
                  caseObject={caseObject}
                  eventCardStyle={classes.casesNewCard}
                  eventCardTextColor={classes.casesClosedCasesTextColor}
                />
              ))
            ) : (
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <CheckCircleOutlineIcon className={classes.circleIcon} />
                </Grid>

                <Grid item>
                  <Text variant="subtitle1">No Cases to Display</Text>
                </Grid>
              </Grid>
            )}
          </TabPanel>
        </TabPanel>
      </Grid>
      {locationCodeVal === '4' || locationCodeVal === '5' ? null : (
        <Grid
          container
          direction="column"
          alignContent="center"
          justifyContent="center"
          className={classes.footerItem}
        >
          <Footer>
            <Text
              onClick={handleOneStopURL}
              underline="none"
              varaint="subtitle1"
              color="secondary"
              className={classes.studentServiceCenterFooter}
            >
              Learn more about the One Stop
              {locationCodeVal === '1' && (
                <>
                  <br />
                  <Text className={classes.oneStopSubheader}>
                    Dr. Samuel Dewitt Proctor Hall, 65 Davidson Rd, Piscataway,
                    NJ 848-445-4636
                  </Text>
                </>
              )}
            </Text>
          </Footer>
        </Grid>
      )}
    </Grid>
  )
}

const Case = ({
  widgetId,
  title,
  dispatch,
  caseObject,
  eventCardStyle,
  eventCardTextColor,
}) => {
  const handleEventClick = () => {
    // CHANGE REQUESTED 4/29 to https://rutgers.my.site.com/OneStopPortal/s/my-cases
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        title,
        `${process.env.REACT_APP_STUDENT_SERVICE_CENTER_HYPERLINK}/s/my-cases`,
      )
    }
  }

  return (
    <Card
      className={eventCardStyle}
      border={2}
      key={caseObject.Id}
      variant="outlined"
    >
      <Grid
        container
        justifyContent="flex-start"
        direction="column"
        spacing={1}
      >
        <Grid item>
          <Link
            href={`${process.env.REACT_APP_STUDENT_SERVICE_CENTER_HYPERLINK}/s/my-cases`}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            onClick={handleEventClick}
          >
            <Text variant="subtitle1">
              {caseObject.Subject ? caseObject.Subject : 'No Subject'}
            </Text>
          </Link>
        </Grid>

        <Grid item container direction="column">
          <Grid item>
            <Text variant="subtitle2">{caseObject.Reason}</Text>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Grid item>
              <Text variant="subtitle2">{caseObject.CaseNumber}</Text>
            </Grid>
            <Grid item>
              <Text variant="subtitle2" className={eventCardTextColor}>
                {caseObject.Status}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

const RBHSStudentNewCaseTab = ({ widgetId, dispatch, classes }) => {
  const handleEventClick = (e) => {
    // get the inner text and href attribute from event click
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        e.target.innerText,
        e.currentTarget.getAttribute('href'),
      )
    }
  }
  return (
    <Box className={classes.rbhsStudentNewCases}>
      <Box display="flex" justifyContent="space-between" marginTop="10px">
        <Box
          style={{
            background: '#cc0033',
            borderRadius: '8px',
            width: 150,
            marginLeft: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link
            href="https://academichealth.rutgers.edu/education-training#schools"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            title="Admissions Registrar"
            onClick={handleEventClick}
            component="a"
          >
            <Text
              variant="h5"
              style={{
                fontWeight: 'bold',
                marginBottom: 5,
                textAlign: 'center',
                color: 'white',
              }}
            >
              Admissions
            </Text>
          </Link>
        </Box>
        <Box
          style={{
            background: '#cc0033',
            color: 'white',
            borderRadius: '8px',
            width: 150,
            height: 60,
            marginRight: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link
            href="https://oasa.rbhs.rutgers.edu/registrar/rbhs-school-information-contacts/"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            title="Financial Aid"
            onClick={handleEventClick}
            component="a"
          >
            <Text
              variant="h5"
              style={{
                fontWeight: 'bold',
                marginBottom: 5,
                textAlign: 'center',
                color: 'white',
              }}
            >
              Financial Aid
            </Text>
          </Link>
        </Box>
      </Box>
      <Card
        variant="outlined"
        style={{ padding: '5px 20px', margin: '10px auto', width: '95%' }}
      >
        <Text variant="body1" align="left">
          Rutgers Health-Newark
        </Text>
        <Text
          variant="body1"
          style={{ fontWeight: 'bold', marginBottom: 5 }}
          align="left"
        >
          Office of Financial Aid
        </Text>
        <Text
          variant="body1"
          style={{ fontWeight: 'bold', marginBottom: 5 }}
          align="left"
        >
          <Link
            href="https://maps.rutgers.edu/#/?click=true&selected=7267"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            title="ADMC"
            onClick={handleEventClick}
          >
            ADMC
          </Link>{' '}
          1208
        </Text>
        <Text variant="body1" align="left">
          30 Bergen Street P.O. Box 1709 <br />
          Newark‚ New Jersey 07101–1709
        </Text>
        <StudentServiceCenterAccordion
          classes={classes}
          title={<Text variant="subtitle1">Office Hours</Text>}
        >
          <Box marginBottom="5px">
            <ul style={{ padding: '0px' }}>
              <li>
                Office hours: <br />
                8:30am – 5:00pm Monday – Friday
              </li>
              <li>
                Phone:{' '}
                <Link
                  href="tel:973-972-4376"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  onClick={handleEventClick}
                >
                  <IconPicker iconName="phone" color="#cc0033" /> 973-972-4376{' '}
                </Link>
                <br />
                Fax: <IconPicker iconName="fax" color="#cc0033" /> 973-972-7436
                <br />
                <Link
                  href="mailto:rbhs_nwk@ofa.rutgers.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  onClick={handleEventClick}
                >
                  <IconPicker iconName="email" color="#cc0033" />{' '}
                  rbhs_nwk@ofa.rutgers.edu
                </Link>
              </li>
              <li>Please include your university ID number in any email.</li>
            </ul>
          </Box>
        </StudentServiceCenterAccordion>
      </Card>
      <Card
        variant="outlined"
        style={{ padding: '5px 20px', margin: '10px auto', width: '95%' }}
      >
        <Text
          variant="h5"
          style={{ fontWeight: 'bold', marginBottom: 5, textAlign: 'center' }}
          align="left"
        >
          Rutgers Health-Piscataway
        </Text>
        <Text
          variant="h5"
          style={{ fontWeight: 'bold', marginBottom: 5, textAlign: 'center' }}
          align="left"
        >
          Office of Financial Aid
        </Text>
        <Text
          variant="body1"
          style={{ fontWeight: 'bold', marginBottom: 5 }}
          align="left"
        >
          <Link
            href="https://maps.rutgers.edu/#/?click=true&selected=3688"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            title="Research Tower"
            onClick={handleEventClick}
          >
            Research Tower
          </Link>
        </Text>
        <Text variant="body1" align="left">
          675 Hoes Lane‚ C103 <br />
          Piscataway‚ New Jersey 08854
        </Text>
        <StudentServiceCenterAccordion
          classes={classes}
          title={<Text variant="subtitle1">Office Hours</Text>}
        >
          <Box marginBottom="5px">
            <ul style={{ padding: '0px' }}>
              <li>
                Office hours: <br />
                8:30am – 5:00pm Monday – Friday
              </li>
              <li>
                Phone:{' '}
                <Link
                  href="tel:973-972-4376"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  onClick={handleEventClick}
                >
                  <IconPicker iconName="phone" color="#cc0033" /> 732-235-4689
                </Link>
                <br />
                Fax: <IconPicker iconName="fax" color="#cc0033" /> 732-235-3264
                <br />
                <Link
                  href="mailto:rbhs_pisc@ofa.rutgers.edu"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  onClick={handleEventClick}
                >
                  <IconPicker iconName="email" color="#cc0033" />{' '}
                  rbhs_pisc@ofa.rutgers.edu
                </Link>
              </li>
              <li>Please include your university ID number in any email.</li>
            </ul>
          </Box>
        </StudentServiceCenterAccordion>
        <Box>
          <Text
            variant="body1"
            style={{ fontWeight: 'bold', marginBottom: 5 }}
            align="left"
          >
            Student Accounting
          </Text>
          <Text variant="body1" align="left">
            Rutgers Health students seeking assistance with their refunds, late
            fees, financial holds, payment plans, or anything related to their
            term bill, should{' '}
            <Link
              href="https://go.rutgers.edu/rbhs-sabc"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              onClick={handleEventClick}
            >
              submit an inquiry.
            </Link>
          </Text>
        </Box>
      </Card>
    </Box>
  )
}

const StudentServiceCenterAccordion = ({ title, children, classes }) => (
  <Accordion
    variant="outlined"
    style={{
      marginTop: '8px',
      borderColor: 'transparent',
    }}
  >
    <AccordionSummary
      classes={{ root: classes.accordion }}
      expandIcon={<ExpandMore />}
    >
      {title}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
)

export default StudentServiceCenter
