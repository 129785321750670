import { makeStyles } from '@material-ui/core/styles'

export const studentServiceCenterStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    padding: 0,
    height: 475,
  },
  modalTabs: {
    width: 335,
    height: 35,
    minHeight: 35,
    [theme.breakpoints.down(570)]: {
      width: 300,
    },
  },
  oneStopHeader: {
    color: theme.palette.base.red,
    margin: '6px 0 !important',
  },
  oneStopHeaderSpan: {
    color: theme.palette.base.gray,
  },
  searchBar: {
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 5,
  },
  accordion: {
    padding: '0px 0px',
  },
  divider: {
    backgroundColor: theme.palette.base.red,
    height: '3px',
  },
  linkText: {
    '&:hover': {
      color: theme.palette.base.red,
    },
  },
  eventCard: {
    width: '95%',
    padding: 10,
    margin: '10px auto',
  },
  eventGridItem: {
    marginTop: 20,
    marginBottom: 20,
  },
  rbhsStudentNewCases: {
    marginTop: 0,
    marginBottom: 20,
  },
  descriptionGridItem: {
    padding: '20px 5px',
  },
  caseButtonGridItem: {
    display: 'flex',
    paddingTop: 20,
    marginBottom: 20,
  },
  newCaseButton: {
    borderRadius: 25,
    backgroundColor: theme.palette.base.red,
    color: theme.palette.base.white,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tabPanel: {
    flex: 1,
    overflowY: 'auto',
  },
  smallTabPanel: {
    width: '100%',
  },
  casesNewCard: {
    width: '95%',
    padding: 10,
    margin: '10px auto',
  },
  casesNewCasesTextColor: {
    color: theme.palette.base.green,
  },
  casesClosedCasesTextColor: {
    color: theme.palette.base.red,
  },
  casesClosedCard: {
    width: '95%',
    padding: 10,
    margin: '10px auto',
  },
  gridContainer: {
    height: '100%',
    display: 'flex',
  },
  oneStopSubheader: {
    color: theme.palette.primary,
    fontSize: 9,
    textAlign: 'center',
  },
  studentServiceCenterFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.base.blue,
    },
  },
  circleIcon: {
    fontSize: 70,
    color: theme.palette.base.green,
  },
}))

export default studentServiceCenterStyles
